import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { Link } from "gatsby";

const UseStyles = makeStyles({
  item: {
    textDecoration: "none",

    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
    "& .MuiButton-root": {
      width: "100%",
      borderRadius: "0",
      textTransform: "none",
      backgroundColor: "white",
      boxShadow: "none",
      borderBottom: "1px solid lightgrey",

      "& .MuiButton-label": {
        justifyContent: "left",
      },
    },
  },

  subItem: {
    "& .MuiButton-root": {
      paddingLeft: "2rem",
      backgroundColor: "rgba(211, 211, 211, .5)",
    },
  },
});

type NavbarLinkProps = {
  link: { Name: string; Path: string };
  subItem?: boolean;
};

/*
 * link ->
 *  - Name -> The text to display
 *  - Path -> The route for the link
 * subItem -> If true, indent and darker gray
 */
const NavbarLink = (props: NavbarLinkProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Link
          to={props.link.Path}
          className={
            props.subItem ? `${Classes.item} ${Classes.subItem}` : Classes.item
          }
        >
          <Button variant="contained">{props.link.Name}</Button>
        </Link>
      </Grid>
    </React.Fragment>
  );
};

export default NavbarLink;
