import React from "react";
import { ProductsPanel as Constants } from "../../../helpers/constants";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { StaticQuery, graphql, Link } from "gatsby";

const UseStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    marginTop: "-1rem",
  },

  panel: {
    position: "absolute",
    zIndex: 1,
    borderBottom: "3px solid #AFBD21",
    backgroundColor: "rgba(27,27,27,.9)",
    padding: ".5rem 0 .5rem 0",
    width: "auto",

    [theme.breakpoints.up("md")]: {
      margin: "0 24px 0 24px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1232px",
      margin: "auto",
      left: "0",
      right: "0",
    },
  },

  brand: {
    backgroundColor: "#AFBD21",
    minWidth: "10.25rem",
    width: "100%",
    maxWidth: "14.25rem",
    margin: ".5rem",

    "& a": {
      textDecoration: "none",
    },
  },

  button: {
    textTransform: "none",
    padding: ".5rem",
    width: "100%",
  },

  hide: {
    display: "none",
  },
}));

const Query = graphql`
  query {
    crystallize {
      catalogue {
        children {
          name
          path
        }
      }
    }
  }
`;

type ProductsPanelProps = {
  showPanel: boolean;
  setShowPanel: (show: boolean) => void;
};

/*
 * showPanel -> Whether or not to show this component
 * setShowPanel -> React state function for showPanel
 */
const ProductsPanel = (props: ProductsPanelProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <StaticQuery
      query={Query}
      render={(data) => (
        <React.Fragment>
          <div className={Classes.container}>
            <Grid
              container
              justify="center"
              id={Constants.ProductsPanelId}
              onMouseLeave={() => props.setShowPanel(false)}
              className={props.showPanel ? Classes.panel : Classes.hide}
            >
              {data.crystallize.catalogue.children.map((child, index) => {
                if (!Constants.ExcludedPaths.includes(child.path)) {
                  return (
                    <Grid item key={index} className={Classes.brand}>
                      <Link to={Constants.ProductsPath + child.path}>
                        <Button className={Classes.button}>{child.name}</Button>
                      </Link>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
              <Grid item className={Classes.brand}>
                <Link to={Constants.USAMadePath}>
                  <Button className={Classes.button}>
                    {Constants.USAMadeText}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    />
  );
};

export default ProductsPanel;
