import React from "react";
import { Footer as Constants } from "../../helpers/constants";
import { Grid, Container, makeStyles } from "@material-ui/core";
import { Link } from "gatsby";

const UseStyles = makeStyles((theme) => ({
  slogan: {
    color: "lightgrey",
    backgroundColor: "#262626",
    padding: "1.3rem 0 1.3rem 0",
    fontSize: ".85rem",

    "& span": {
      fontStyle: "italic",
      marginLeft: ".25rem",
    },
  },

  linksWrapper: {
    backgroundColor: "#1b1b1b",
  },

  links: {
    padding: "2rem 1rem",
    backgroundColor: "#1b1b1b",
    color: "lightgrey",
    margin: "auto",

    "& .MuiGrid-item": {
      paddingRight: "1.25rem",
    },
    "& a": {
      color: "inherit",
    },

    [theme.breakpoints.up("sm")]: {
      padding: "2rem 1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1280px",
    },
  },
}));

const Footer = (): JSX.Element => {
  const Classes = UseStyles();

  return (
    <React.Fragment>
      <div className={Classes.linksWrapper}>
        <Grid container className={Classes.links}>
          {Constants.Links.map((link, index) => (
            <Grid item key={index}>
              <Link to={link.Path}>{link.Name}</Link>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} className={Classes.slogan}>
          <Container maxWidth="lg">
            &copy; 2019 BrandBox.
            <span>Think inside the BrandBox.</span>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Footer;
