import React from "react";
import { makeStyles } from "@material-ui/core";
import { withPrefix } from "gatsby";

const UseStyles = makeStyles({
  image: {
    width: "100%",
    height: "100%",
  },
});

interface ImageProps {
  url: string;
  altText?: string;
}

/*
 * url -> Url of the image to display
 * altText -> Alt text to display with image
 */
const Image = (props: ImageProps): JSX.Element => {
  const AltText = props.altText ?? "";
  const Classes = UseStyles();

  return (
    <img src={withPrefix(props.url)} alt={AltText} className={Classes.image} />
  );
};

export default Image;
