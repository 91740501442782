import { GQLImage } from "../types";

const GlobalConstants = {
  Home: "Home",
  Content: "Content",
  Description: "Description",
  Title: "Title",
  Properties: "Properties",
  Name: "Name",
  Brand: "Brand",
  Variations: "Variations",
  Information: "Information",
  Images: "Images",
  DecorLocImages: "Decoration Location Images",
  Price: "Price",
  Sku: "SKU",

  // 404.tsx
  PageNotFound: "Page Not Found",
  AccessDenied:
    "The requested page or resource either could not be found, or you don't have permission to view it.",

  // about.tsx
  AboutUs: "About Us",

  // contact.tsx
  ContactUs: "Contact Us",
  RequestSent: "Request Sent",
  RequestNotSent: "Request Not Sent",
  RequestReceived:
    "We've received your request and will be in touch as soon as possible.",
  RequestNotReceived:
    "There was an issue sending your request. Please try again in a moment.",

  // how-to-order.tsx
  HowToOrder: "How To Order",

  // privacy-policy.tsx
  PrivacyPolicy: "Privacy Policy",

  // products.tsx
  Products: "Products",

  // selling-ideas.tsx
  SellingIdeas: "Selling Ideas",

  // terms.tsx
  TermsAndConditions: "Terms & Conditions",

  // usa-made.tsx
  UsaMade: "USA Made",

  // virtual-sample.tsx
  VirtualSample: "Virtual Sample",
};

export const CloudBaseURL =
  "https://us-central1-brandbox-295121.cloudfunctions.net";

export const Algolia = {
  AppId: "D40R2811TE",
  IndexName: "BrandBox",
  ApiKey: "903d86fbb4c8526761d35b312db6a028",
};

export const InfoDialog = {
  SuccessTitle: "Request Sent",
  SuccessMessage:
    "We've received your request and will be in touch as soon as possible.",
  FailureTitle: "Request Not Sent",
  FailureMessage:
    "There was an issue sending your request. Please try again in a moment.",
};

export const Search = {
  PathObjName: "Search",
  SearchText: "Search...",
  ArrowUpKey: "ArrowUp",
  ArrowDownKey: "ArrowDown",
  EnterKey: "Enter",
  ProductsPath: "/products",
  InfoComponentName: "Information",
  PriceComponentName: "Price",
  SkuPropertyName: "SKU",
  SearchPath: "/search",
  AltSearchPath: "/search/",
  ShowingResultsText: "Showing results for:",
  QuickLinksText: "Quick Links",
  NoResultsText: "No results found. Please try again with a different search.",
};

export const LoadingSpinner = {
  LoadingText: "Loading Results",
};

export const GridItem = {
  AsLowAsText: " - As Low As: ",
  MoreInfoText: "More Info",
  ProductsPath: "/products",
  InfoComponentName: "Information",
  PriceComponentName: "Price",
};

export const Header = {
  HeaderText:
    "Welcome to BrandBox, your premier supplier of unique brand name promotional products.",
};

export const Navbar = {
  Links: [
    { Name: "Home", Path: "/" },
    { Name: "Our Products", Path: "/products" },
    { Name: "On Sale", Path: "/products/on-sale" },
    { Name: "How to Order", Path: "/how-to-order" },
    { Name: "Virtual Sample", Path: "/virtual-sample" },
    { Name: "Selling Ideas", Path: "/selling-ideas" },
    { Name: "About Us", Path: "/about" },
    { Name: "Contact Us", Path: "/contact" },
  ],
  MouseEnterEvent: "mouseenter",
  MouseLeaveEvent: "mouseleave",
  OurProductsName: "Our Products",
  ProductsPanelId: "productsPanelId",
};

export const Product = {
  NoCustomImageText:
    "* We don't have an image for that combination. Please try another selection.",
};

export const InfoTabs = {
  DetailsText: "Details",
  ProductDescText: "Product Description",
  DecorationLocationText: "Decoration Location Image",
  AdditionalInfoText: "Additional Information",
  NoDecorLocImageText:
    "No decoration location image is available for this product.",
};

export const ProductDetails = {
  NotSpecifiedText: "Not Specified",
};

export const Options = {
  ChooseOptionText: "Choose an option...",
};

export const UsaMade = {
  UsaMadeText: "USA Made",
  DescriptionHtml:
    "<p>Brand name promotional products Made in the USA. American Pride. American Made.</p>",
};

export const Products = {
  UsaMadeText: "USA Made",
  OurProductsText: "Our Products",
  ProductsHeaderText:
    "BrandBox offers brand name products that can be decorated with a custom logo. " +
    "Check out these great brands that can provide quality and functional promotional products.",
  ExcludedFolders: ["Slideshow", "Articles"],
};

export const ProductLinks = {
  ExcludedPaths: ["/articles", "/slideshow"],
  ProductsPath: "/products",
  USAMadePath: "/products/usa-made",
  USAMadeText: "USA Made",
};

export const MobileNavbar = {
  MenuText: "Menu",
  HomeName: "Home",
  OurProductsName: "Our Products",
};

export const ProductsPanel = {
  ExcludedPaths: ["/articles", "/slideshow"],
  ProductsPanelId: "productsPanelId",
  ProductsPath: "/products",
  USAMadePath: "/products/usa-made",
  USAMadeText: "USA Made",
};

export const ProductGrid = {
  SortByName: "sortBy",
  SortDirectionName: "sortDirection",
  ResultsPerPageName: "resultsPerPage",
  CurrentPageName: "currentPage",
  AscendingValue: "ascending",
  NameValue: "name",
};

export const Pagination = {
  CurrentPageName: "currentPage",
};

export const SortBy = {
  SortByName: "sortBy",
  SortByText: "Sort By",
  NameValue: "name",
  PriceValue: "price",
  PopularityValue: "popularity",
  NameText: "Name",
  PriceText: "Price",
  PopularityText: "Popularity",
  SortDirName: "sortDirection",
  TooltipAscText: "Sort Ascending Direction",
  TooltipDescText: "Sort Descending Direction",
  AscendingValue: "ascending",
  DescendingValue: "descending",
};

export const ResultsPerPage = {
  ResultsPerPageText: "Results Per Page",
  ResultsPerPageName: "resultsPerPage",
};

export const Footer = {
  Links: [
    { Name: "Home", Path: "/" },
    { Name: "Our Products", Path: "/products" },
    { Name: "How to Order", Path: "/how-to-order" },
    { Name: "Virtual Sample", Path: "/virtual-sample" },
    { Name: "Selling Ideas", Path: "/selling-ideas" },
    { Name: "About Us", Path: "/about" },
    { Name: "Privacy Policy", Path: "/privacy-policy" },
    { Name: "Terms & Conditions", Path: "/terms" },
    { Name: "Contact Us", Path: "/contact" },
  ],
};

export const Logo = {
  Name: "BrandBox®",
  Path: "/",
  Image: "/brandbox-logo.png",
};

export const Brands = {
  OurBrands: "Our Brands",
  Brands: [
    {
      Name: "Channellock®",
      Path: "/products/channellock",
      Image: "/brand-logos/channellock.png",
    },
    {
      Name: "Crescent®",
      Path: "/products/crescent",
      Image: "/brand-logos/crescent.png",
    },
    {
      Name: "Fiskars®",
      Path: "/products/fiskars",
      Image: "/brand-logos/fiskars.png",
    },
    {
      Name: "Gerber®",
      Path: "/products/gerber",
      Image: "/brand-logos/gerber.png",
    },
    {
      Name: "Johnson®",
      Path: "/products/johnson",
      Image: "/brand-logos/johnson.png",
    },
    {
      Name: "KA-BAR®",
      Path: "/products/ka-bar",
      Image: "/brand-logos/ka-bar.png",
    },
    {
      Name: "Klein Tools®",
      Path: "/products/klein-tools",
      Image: "/brand-logos/klein-tools.png",
    },
    {
      Name: "Lufkin®",
      Path: "/products/lufkin",
      Image: "/brand-logos/lufkin.png",
    },
    {
      Name: "Nalgene®",
      Path: "/products/nalgene",
      Image: "/brand-logos/nalgene.png",
    },
    {
      Name: "Nightstick®",
      Path: "/products/nightstick",
      Image: "/brand-logos/nightstick.png",
    },
    {
      Name: "Streamlight®",
      Path: "/products/streamlight",
      Image: "/brand-logos/streamlight.png",
    },
    { Name: "Wiss®", Path: "/products/wiss", Image: "/brand-logos/wiss.png" },
  ],
};

export const HomePage = {
  CrystallizeHomePageTenant:
    "https://api.crystallize.com/brandbox-homepage/catalogue",
  SlideshowText: "Slideshow",

  // brand-grid.tsx
  BrowseByBrandText: "Browse By Brand",
  BrowseByBrandDetails:
    "We are proud to offer customized products from a variety of premier brands! Click the product card to view more products.",
  ProductImagesDir: "homepage/products/",
  BrandLogosDir: "homepage/brands/",
  BrandNames: [
    "klein-tools",
    "channellock",
    "nightstick",
    "ka-bar",
    "nalgene",
    "gerber",
    "streamlight",
    "crescent",
    "lufkin",
    "johnson",
    "wiss",
    "fiskars",
  ],

  // slideshow.tsx
  SlideUrlText: "Slide URL",
  SlideBannerImageText: "Slide Banner Image",
  SlideTextText: "Slide Text",

  // slide.tsx
  LearnMoreText: "Learn More",
};

export const VirtualSamplePage = {
  // progress-stepper.tsx
  Steps: ["Select a brand", "Select a product", "Fill out request"],
};

/* Virtual Sample Page */
export type VirtualSampleFormState = {
  name: string;
  email: string;
  company: string;
  asiNumber: string;
  phoneNumber: string;
  designNotes: string;
  formIsValid: boolean;
  hasValidSelection: boolean;
};

/* Virtual Sample Page */
export type VirtualSampleDropdownState = {
  label: string;
  value: string;
  valueAsText: string;
  options: { id: string; name: string; defaultImages?: GQLImage[] }[];
  required?: boolean;
  disabled?: boolean;
};

export const SellingIdeas = {
  SellingIdeasText: "Selling Ideas",
  HeaderText:
    "Here are some ideas to help you sell these great brands to your customers.",
  ByIndustryText: "By Industry",

  // selling-idea.tsx
  OurSuggestionsText: "Our Suggestions",
};

export default GlobalConstants;
