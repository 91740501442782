import React from "react";
import { Navbar as Constants } from "../../../helpers/constants";
import { ButtonGroup, Button, makeStyles } from "@material-ui/core";
import { Link } from "gatsby";

const UseStyles = makeStyles({
  buttonGroup: {
    borderRadius: "0",
    backgroundColor: "#1b1b1b",
    width: "100%",

    "& Button": {
      color: "lightgrey",
      backgroundColor: "#1b1b1b",
      "&.MuiButton-root": { padding: "0" },

      "& a": {
        padding: "1rem",
        color: "inherit",
        textDecoration: "none",

        "&:hover": {
          textDecoration: "none",
        },
      },
      "&:hover": {
        backgroundColor: "#AFBD21",
        borderRadius: "0",
      },
    },
  },

  selected: {
    "& a": {
      backgroundColor: "#AFBD21 !important",
      borderRadius: "0",
    },
  },

  lastButton: {
    borderRight: "1px solid lightgrey",
  },
});

/* Handles showing/hiding the navbar panel with additional product brands
 * - event -> Mouse event used to determine if the panel should be shown
 * - setShowPanel -> Toggles visibility of the brands panel
 */
const HandleMouseEvent = (
  event: React.MouseEvent<HTMLAnchorElement>,
  setShowPanel: (show: boolean) => void
) => {
  if (
    event.type === Constants.MouseEnterEvent &&
    event.currentTarget.id === Constants.OurProductsName
  ) {
    setShowPanel(true);
  } else if (
    event.type === Constants.MouseLeaveEvent &&
    event.relatedTarget.id !== Constants.ProductsPanelId
  ) {
    setShowPanel(false);
  }
};

type NavbarProps = {
  setShowPanel: (show: boolean) => void;
  currentPage: string;
};

/*
 * setShowPanel -> Event handler for 'Our Products' hover
 * currentPage -> The page being viewed
 */
const Navbar = (props: NavbarProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" className={Classes.buttonGroup}>
        {Constants.Links.map((link, index) => (
          <Button
            key={index}
            name={Constants.OurProductsName}
            className={`
              ${props.currentPage === link.Path ? Classes.selected : null}
              ${
                index === Constants.Links.length - 1 ? Classes.lastButton : null
              }
            `}
          >
            <Link
              to={link.Path}
              id={link.Name}
              onMouseEnter={(event) =>
                HandleMouseEvent(event, props.setShowPanel)
              }
              onMouseLeave={(event) =>
                HandleMouseEvent(event, props.setShowPanel)
              }
            >
              {link.Name}
            </Link>
          </Button>
        ))}
      </ButtonGroup>
    </React.Fragment>
  );
};

export default Navbar;
