import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Search as Constants } from "../../../../helpers/constants";
import {
  FormControl,
  Input,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  search: {
    backgroundColor: "white",
    marginTop: "1.2rem",

    "& .MuiInput-input": {
      marginLeft: ".85rem",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "2px solid #AFBD21",
    },
  },

  inputAdornment: {
    marginRight: "0.65rem",

    "&:hover": {
      cursor: "pointer",
    },
  },

  searchIcon: {
    color: "#AFBD21",
  },
});

type SearchBoxProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

/*
 * value -> React state search query
 * onChange -> Event handler for query changes
 * onKeyDown -> Event handler for keyboard navigation
 * onFocus -> Event handler for search box focus
 * onBlur -> Event handler for search box blur
 * performSearch -> Search using the query
 */
const SearchBox = (props: SearchBoxProps): JSX.Element => {
  const Classes = useStyles();

  return (
    <FormControl>
      <Input
        placeholder={Constants.SearchText}
        className={Classes.search}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        endAdornment={
          <InputAdornment
            position="end"
            className={Classes.inputAdornment}
            onClick={props.onClick}
          >
            <SearchIcon className={Classes.searchIcon} />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchBox;
