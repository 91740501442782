import React from "react";
import Navbar from "./header/navbar";
import Logo from "./header/logo";
import Search from "./header/search";
import ProductsPanel from "./header/products-panel";
import MobileNavbar from "./header/mobile-navbar";
import MobileHeader from "./header/mobile-header";
import { Header as Constants } from "../../helpers/constants";
import { Container, Grid, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles((theme) => ({
  title: {
    color: "lightgrey",
    backgroundColor: "#1b1b1b",
    width: "100%",
    padding: ".5rem 0 .5rem 0",
    fontStyle: "italic",
    fontSize: ".85rem",
  },

  content: {
    backgroundColor: "#c9c9c9",
    paddingTop: "1rem",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  search: {
    textAlign: "right",
  },

  navbar: {
    backgroundColor: "#c9c9c9",
    marginBottom: "1rem",
    paddingTop: "1rem",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  mobileNavbar: {
    backgroundColor: "#c9c9c9",
    marginBottom: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

type HeaderProps = {
  currentPage: string;
};

/*
 * currentPage -> The page being viewed
 */
const Header = (props: HeaderProps): JSX.Element => {
  const Classes = UseStyles();
  const [ShowPanel, SetShowPanel] = React.useState(false);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={Classes.title}>
        <Container maxWidth="lg">{Constants.HeaderText}</Container>
      </Grid>
      <Grid item xs={12} className={Classes.content}>
        <Container maxWidth="lg">
          <Grid container spacing={0} justify="space-between">
            <Grid item xs={2}>
              <Logo />
            </Grid>
            <Grid item xs={6} className={Classes.search}>
              <Search />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <MobileHeader />
      <Grid item xs={12} className={Classes.navbar}>
        <Container maxWidth="lg">
          <Navbar currentPage={props.currentPage} setShowPanel={SetShowPanel} />
        </Container>
      </Grid>
      <Grid item xs={12} className={Classes.mobileNavbar}>
        <Container maxWidth="lg">
          <MobileNavbar />
        </Container>
      </Grid>
      <Grid item xs={12}>
        <ProductsPanel showPanel={ShowPanel} setShowPanel={SetShowPanel} />
      </Grid>
    </Grid>
  );
};

export default Header;
