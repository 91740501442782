import React from "react";
import NavbarLink from "./navbar-link";
import { ProductLinks as Constants } from "../../../../helpers/constants";

type ProductLinksProps = { links: { name: string; path: string }[] };

/*
 * links -> Link data from Crystallize
 */
const ProductLinks = (props: ProductLinksProps): JSX.Element => {
  return (
    <React.Fragment>
      {props.links.map((link, index) => {
        if (!Constants.ExcludedPaths.includes(link.path)) {
          return (
            <NavbarLink
              key={index}
              link={{
                Name: link.name,
                Path: Constants.ProductsPath + link.path,
              }}
              subItem={true}
            />
          );
        } else {
          return null;
        }
      })}
      <NavbarLink
        subItem={true}
        link={{ Name: Constants.USAMadeText, Path: Constants.USAMadePath }}
      />
    </React.Fragment>
  );
};

export default ProductLinks;
