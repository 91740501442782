import React from "react";
import Image from "../image";
import { Link } from "gatsby";
import { Grid, Divider, Button, makeStyles } from "@material-ui/core";
import { Brands as Constants } from "../../helpers/constants";

const UseStyles = makeStyles({
  brandsWrapper: {
    textAlign: "center",
  },

  button: {
    width: "50%",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    color: "black !important",
    background: "#AFBD21 !important",
  },

  divider: {
    color: "#AFBD21",
    padding: "auto 0",
    margin: "auto 0",
    textAlign: "center",
  },

  brands: {
    padding: "1rem 0 1rem 0",
    "& div": {
      margin: "auto 0 auto 0",
    },
  },
});

const Brands = (): JSX.Element => {
  const Classes = UseStyles();

  return (
    <div className={Classes.brandsWrapper}>
      <Button
        disabled
        variant="contained"
        size="large"
        className={Classes.button}
      >
        {Constants.OurBrands}
      </Button>
      <Divider className={Classes.divider} />
      <Grid container spacing={3} justify="center" className={Classes.brands}>
        {Constants.Brands.map((brand, index) => (
          <Grid item key={index} lg={2} md={3} sm={4} xs={6}>
            <Link to={brand.Path}>
              <Image url={brand.Image} altText={brand.Name} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Brands;
