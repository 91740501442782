import React from "react";
import Image from "../../image";
import { makeStyles } from "@material-ui/core";
import { Logo as Constants } from "../../../helpers/constants";
import { Link } from "gatsby";

const UseStyles = makeStyles({
  logo: {
    width: "205.33px",
    height: "68.78px",
  },
});

const Logo = (): JSX.Element => {
  const Classes = UseStyles();

  return (
    <Link to={Constants.Path}>
      <div className={Classes.logo}>
        <Image url={Constants.Image} altText={Constants.Name} />
      </div>
    </Link>
  );
};

export default Logo;
