import React from "react";
import algoliasearch from "algoliasearch";
import SearchBox from "./search/search-box";
import SearchResults from "./search/search-results";
import { Search as Constants, Algolia } from "../../../helpers/constants";
import { navigate } from "gatsby";

const Search = (): JSX.Element => {
  const Client = algoliasearch(Algolia.AppId, Algolia.ApiKey);
  const Index = Client.initIndex(Algolia.IndexName);
  const [SearchState, SetSearchState] = React.useState({
    query: "",
    results: [],
    refs: [],
    focusedIndex: -1,
    visible: false,
  });

  let refs = null;

  /* Called whenever the text in the search box changes */
  const HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const NewValue = event.currentTarget.value;

    Index.search(NewValue).then((response) => {
      const Hits = response.hits;

      refs = Hits?.map(() => React.createRef());
      SetSearchState((SearchState) => ({
        ...SearchState,
        results: Hits,
        refs: refs,
      }));
    });

    SetSearchState((SearchState) => ({
      ...SearchState,
      query: NewValue || "",
      focusedIndex: -1,
    }));
  };

  /* Called when the search box is clicked on */
  const HandleFocus = () => {
    SetSearchState((SearchState) => ({
      ...SearchState,
      visible: true,
    }));
  };

  /* Called when the search box is clicked out of */
  const HandleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const SearchResults = SearchState.refs.map((ref) => ref.current);

    if (!SearchResults.includes(event.relatedTarget)) {
      SetSearchState((SearchState) => ({
        ...SearchState,
        focusedIndex: -1,
        visible: false,
      }));
    }
  };

  /* Sets the index of the selected search result */
  const SetFocusedIndex = (index: number) => {
    SetSearchState((SearchState) => ({
      ...SearchState,
      focusedIndex: index,
    }));
  };

  /* When a product is selected, this performs the search and navigates to the results page */
  const PerformSearch = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    // https://github.com/gatsbyjs/gatsby/issues/17960
    const OnSearchPage = window.location.pathname === Constants.SearchPath;
    const NextRoute = OnSearchPage
      ? Constants.AltSearchPath
      : Constants.SearchPath;

    event.currentTarget.blur();

    SetSearchState({
      query: "",
      results: [],
      refs: [],
      focusedIndex: -1,
      visible: false,
    });

    navigate(NextRoute, {
      state: {
        query: SearchState.query,
      },
    });
  };

  /* Handles arrow key search result list navigation and enter keypress for performing searches */
  const HandleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const IsAnySelected = SearchState.focusedIndex !== -1;
    const IsFirstInList = SearchState.focusedIndex <= 0;
    const IsLastInList =
      SearchState.focusedIndex >= SearchState.refs.length - 1;
    const SelectedProduct = SearchState.results[SearchState.focusedIndex];

    if (event.key === Constants.ArrowDownKey && !IsLastInList) {
      SetFocusedIndex(SearchState.focusedIndex + 1);
    } else if (event.key === Constants.ArrowUpKey && !IsFirstInList) {
      SetFocusedIndex(SearchState.focusedIndex - 1);
    } else if (event.key === Constants.EnterKey && IsAnySelected) {
      window.location.href = Constants.ProductsPath + SelectedProduct.path;
    } else if (
      event.key === Constants.EnterKey &&
      SearchState.query.length > 0
    ) {
      PerformSearch(event);
    }
  };

  return (
    <>
      <SearchBox
        value={SearchState.query}
        onChange={HandleChange}
        onKeyDown={HandleKeyDown}
        onFocus={HandleFocus}
        onBlur={HandleBlur}
        onClick={PerformSearch}
      />
      <SearchResults state={SearchState} onHover={SetFocusedIndex} />
    </>
  );
};

export default Search;
