import React from "react";
import Logo from "./logo";
import Search from "./search";
import { Grid, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles((theme) => ({
  mobileHeader: {
    width: "100%",
    padding: "1rem 16px 0 16px",
    backgroundColor: "#c9c9c9",

    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .gatsby-image-wrapper": {
      width: "70%",
      height: "auto",
    },
    "& .MuiList-root": {
      width: "100%",
    },

    [theme.breakpoints.up("sm")]: {
      padding: "1rem 24px 0 24px",

      "& .gatsby-image-wrapper": {
        width: "50%",
        height: "auto",
      },
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const MobileHeader = (): JSX.Element => {
  const Classes = UseStyles();

  return (
    <React.Fragment>
      <div className={Classes.mobileHeader}>
        <Grid item xs={12}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Search />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default MobileHeader;
